import { Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Translation Higher Order Component
import { translate } from 'react-switch-lang';

const Booking = ({ t }) => {
  return (
    <React.Fragment>
      <Row className='justify-content-center'>
        <a
          rel='external nofollow noopener'
          target='_blank'
          className='booking_button clickable open_booking-iframe-navbutton text-uppercase'
        >
          <FontAwesomeIcon icon={['fas', 'calendar-check']} />
          &nbsp;{t('navbar.booking')}
        </a>
      </Row>
      <Row className='justify-content-center pb-5 text-success text-strong font-weight-bold'>
        {t('main.call_us')}
      </Row>
    </React.Fragment>
  );
};
export default translate(Booking);
