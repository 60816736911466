import React, { useState } from 'react';
import { Input, Form, FormGroup, Button, Container } from 'reactstrap';
// Translation Higher Order Component
import { translate } from 'react-switch-lang';

const ContactForm = ({ t }) => {
	const [ status, setStatus ] = useState({
		submitted: false,
		submitting: false,
		info: { error: false, msg: null }
	});

	const [ inputs, setInputs ] = useState({
		email: '',
		name: '',
		telephone: '',
		message: ''
	});

	const handleResponse = (status, msg) => {
		if (status === 200) {
			setStatus({
				submitted: true,
				submitting: false,
				info: { error: false, msg: msg }
			});
			setInputs({
				email: '',
				name: '',
				telephone: '',
				message: ''
			});
		} else {
			setStatus({
				info: { error: true, msg: msg }
			});
		}
	};

	const handleOnChange = (e) => {
		e.persist();
		setInputs((prev) => ({
			...prev,
			[e.target.id]: e.target.value
		}));
		setStatus({
			submitted: false,
			submitting: false,
			info: { error: false, msg: null }
		});
	};

	const handleOnSubmit = async (e) => {
		e.preventDefault();
		setStatus((prevStatus) => ({ ...prevStatus, submitting: true }));
		const res = await fetch('/api/send', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(inputs)
		});
		const text = await res.text();
		handleResponse(res.status, text);
	};

	return (
		<Container>
			<Form onSubmit={handleOnSubmit}>
				<FormGroup>
					<label htmlFor="email">Email</label>
					<span className="red">*</span>
					<Input
						autoComplete="new-password"
						id="email"
						type="email"
						placeholder="name@example.com"
						onChange={handleOnChange}
						required
						value={inputs.email}
					/>
					<label htmlFor="telephone">{t('contacts.phone')}</label>
					<span className="red">*</span>
					<Input
						autoComplete="new-password"
						id="telephone"
						type="tel"
						placeholder="40-(770)-888-444"
						onChange={handleOnChange}
						required
						value={inputs.telephone}
					/>
					<label htmlFor="name">{t('contacts.name')}</label>
					<span className="red">*</span>
					<Input
						autoComplete="new-password"
						id="name"
						type="text"
						placeholder="John Doe"
						onChange={handleOnChange}
						required
						value={inputs.name}
					/>
					<label htmlFor="message">{t('contacts.message')}</label>
					<span className="red">*</span>
					<Input
						autoComplete="new-password"
						id="message"
						onChange={handleOnChange}
						required
						value={inputs.message}
						bsSize="lg"
						placeholder={t('contacts.message_placeholder')}
						rows="3"
						type="textarea"
					/>
					<br />
					<Button className="booking_button" color="info" type="submit" disabled={status.submitting}>
						{!status.submitting ? !status.submitted ? (
							t('contacts.submit')
						) : (
							t('contacts.submitted')
						) : (
							t('contacts.submitting')
						)}
					</Button>
					{/* <br />
					{status.info.error && <div className="error">Error: {status.info.msg}</div>}
					{!status.info.error && status.info.msg && <div className="success">{status.info.msg}</div>} */}
				</FormGroup>
			</Form>
		</Container>
	);
};

export default translate(ContactForm);
